import slidingLogos from './_partials/functionDetail/slidingLogos'
// import progressBar from './_partials/redesign/pillar/progressBar'
import stickySidebar from './_partials/pillar/stickySidebar'
import stickySubNav from './_partials/functionDetail/stickySubNav'
import functionsHomeStickySubNav from './_partials/functions/functionsHomeStickySubNav'
import animateAnchorTextLinks from './_partials/functions/animateAnchorTextLinks'

document.addEventListener('DOMContentLoaded', () => {
  slidingLogos()
  // progressBar()
  stickySidebar()
  stickySubNav()
  functionsHomeStickySubNav()
  animateAnchorTextLinks()
})
